// Import useEffect, useState from React, and any Bootstrap components you need
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from "firebase/auth";



const TopPanels = () => {
  const [topPanels, setTopPanels] = useState([]);
  const topPanelsApi = process.env.REACT_APP_TOP_PANELS;
  useEffect(() => {
    const fetchTopPanels = async () => {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken(true);
            console.log("fetching from : ",topPanelsApi)
            const response = await axios.get(topPanelsApi, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            setTopPanels(response.data);
            console.log(response.data);
          } catch (error) {
            console.error("Error fetching top panels:", error);
          }
        }
      });
    };

    fetchTopPanels();
  }, []);

  

  return (
    <Card>
      <Card.Header>Top 5 Panels Used</Card.Header>
      <Card.Body>
        {topPanels.length > 0 ? (
          <ul>
            {topPanels.map((panel, index) => (
              <li key={index}>{`${panel.manufacturer} - ${panel.name} (Used ${panel.frequency} times)`}</li>
            ))}
          </ul>
        ) : (
          <p>No panel data available.</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default TopPanels;
