import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import TopPanels from "./TopPanels";
import ActiveUsers from "./ActiveUsers";
import TopUsers from "./TopUsers";

const Dashboard = () => {
  const [analytics, setAnalytics] = useState({
    totalProjects: 0,
    topOutputSizes: [],
    topScreenSizes: [],
    topPanelSizes:[],
    // Initialize states for other analytics
  });

  const analyticsApi = process.env.REACT_APP_ADMIN_ANALYTICS;

  useEffect(() => {
    const fetchAnalytics = async () => {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken(true);
            const response = await axios.get(analyticsApi, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            setAnalytics(response.data);
            console.log(response.data);
          } catch (error) {
            console.error("Error fetching dashboard analytics:", error);
          }
        }
      });
    };

    fetchAnalytics();
  }, []);

  const AnalyticsCard = ({ title, data, formatLabel }) => (
    <Card className="mb-3">
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        {data.map((item, index) => (
          <Card.Text key={index}>
            {formatLabel(item)}
          </Card.Text>
        ))}
      </Card.Body>
    </Card>
  );

  return (
    <Container fluid>  
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          <h1>Dashboard Analytics</h1>
          <p>Total Projects: {analytics.totalProjects}</p>
          <Row className="mb-3">
            <Col>
            <TopPanels />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
            <ActiveUsers />
            <TopUsers />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
            <AnalyticsCard
            title="Top 5 Output Sizes"
            data={analytics.topOutputSizes}
            formatLabel={({ width, height, frequency }) => `Width: ${width}, Height: ${height}, Frequency: ${frequency}`}
          />
            </Col>
            <Col md={3}>
            <AnalyticsCard
            title="Top 5 Panel Sizes"
            data={analytics.topPanelSizes}
            formatLabel={({ panel_width, panel_height, frequency }) => `Width: ${panel_width}, Height: ${panel_height}, Frequency: ${frequency}`}
          />
            </Col>
            <Col md={3}>
            <AnalyticsCard
            title="Top 5 Screen Sizes"
            data={analytics.topScreenSizes}
            formatLabel={({ map_width, map_height, frequency }) => `Map Width: ${map_width}, Map Height: ${map_height}, Frequency: ${frequency}`}
          />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
