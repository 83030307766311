import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const ActiveUsers = () => {
  const [activeUserCount, setActiveUserCount] = useState(0);
  const activeUsersApi = process.env.REACT_APP_ACTIVE_USERS;
  

  useEffect(() => {
    const fetchProjectCounts = async () => {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken(true);
           
            const response = await axios.get(activeUsersApi, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            setActiveUserCount(response.data.active_user_count);
            console.log("active users response :",response.data.active_user_count)
          } catch (error) {
            console.error("Error fetching active user count:", error);
          }
        }
      });
    };

    fetchProjectCounts();
  }, []);


  return (
    <Card>
    <Card.Header>Active User Count</Card.Header>
    <Card.Body>
    {`Total active users: ${activeUserCount}`}
    </Card.Body>
  </Card>
  );
};

export default ActiveUsers;