// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDiCy1dem0Mvo14SRBF4DQlMR0cm-vrJz8",
    authDomain: "blinking-things-admin.firebaseapp.com",
    projectId: "blinking-things-admin",
    storageBucket: "blinking-things-admin.appspot.com",
    messagingSenderId: "583589748527",
    appId: "1:583589748527:web:83295ccdcf13f8156e8bda",
    measurementId: "G-BWB0SWER5D"  
  };

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
