import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { SignInModal } from './components/SignIn';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./firebaseConfig";
import Header from './components/Header';
import Dashboard from './components/Dashboard';

const App = () => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [user, setUser] = useState(null); // Add a state for the user

  const adminApi = process.env.REACT_APP_ADMIN_API;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, try to access the admin-only endpoint
        //await accessAdminEndpoint();
        setUser(user); // Update the user state
        setShowSignInModal(false); // Optionally hide the sign-in modal if it's still showing
      } else {
        // No user is signed in, show the sign-in modal
        setUser(null); // Clear the user state
        setShowSignInModal(true);
      }
    });

    // Clean up the subscription on component unmount
    return () => unsubscribe();
  }, []);

  const accessAdminEndpoint = async () => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken(true);
    if (token) {
      try {
        const response = await fetch(adminApi, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        const text = await response.text(); // Get the response body as text
        console.log(text); // Log the raw text
  
        // Attempt to parse the text as JSON only if the response is OK and not empty
        if (response.ok && text) {
          const data = JSON.parse(text);
          console.log(data); // Process your data here
        } else {
          console.error('Failed to access admin-only endpoint or response is empty');
        }
      } catch (error) {
        console.error('Error fetching admin-only endpoint:', error);
      }
    }
  };

  return (
    <>{ user ? (
    <div>
      <Header user={user} /> {/* Pass the user state to the Header */}
      <Dashboard />
      {/* Your app content */}
      
    </div>
    ):(
      <SignInModal show={showSignInModal} onHide={() => setShowSignInModal(false)} />
    )}
    </>
  );
};

export default App;
