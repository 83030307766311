import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const TopUsers = () => {
  const [counts, setCounts] = useState([]);
  const topUsersApi = process.env.REACT_APP_TOP_USERS;
  

  useEffect(() => {
    const fetchProjectCounts = async () => {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken(true);
           
            const response = await axios.get(topUsersApi, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            setCounts(response.data);
            console.log(response.data);
          } catch (error) {
            console.error("Error fetching top users:", error);
          }
        }
      });
    };

    fetchProjectCounts();
  }, []);


  return (
    <Card>
    <Card.Header>Top Users</Card.Header>
    <Card.Body>
      {counts.length > 0 ? (
        <ul>
          {counts.map((item, index) => (
             <li key={index}>{`${item.user_email}: ${item.project_count} projects`}</li>
          ))}
        </ul>
      ) : (
        <p>No counts available.</p>
      )}
    </Card.Body>
  </Card>
  );
};

export default TopUsers;