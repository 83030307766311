import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { getAuth, signOut } from 'firebase/auth';
import LogoDark from "../assets/asterisklogo_sm_dark.png";

const Header = ({ user }) => {
  const auth = getAuth();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      // Optionally, redirect the user or update the state to reflect the sign-out
    }).catch((error) => {
      // An error happened.
      console.error('Sign out error:', error);
    });
  };

  return (
    <Navbar bg="light" expand="lg">
        <img
              className="navLogo mx-1"
              alt="pixel mapulator logo"
              src={LogoDark}
            />
      <Navbar.Brand href="#home">blinkingthings - Admin Dashboard</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="justify-content-end flex-grow-1 pe-2">
        
          {user ? (
            <Button onClick={handleSignOut} variant="outline-success">Sign Out</Button>
          ) : (
            <Button variant="outline-primary">Sign In</Button>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
